import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { ArrowBack } from "@mui/icons-material";
import _ from "../../../util/lodash";
import toast from "react-hot-toast";
import useForm from "../../../hooks/useForm";
import { useDispatch } from "react-redux";
import {
  getCustomer,
  saveCustomer,
  updateCustomer,
} from "../../../redux/customer/customerSlice";
import DropzoneFileUploader from "../../../components/common/DropzoneFileUploader";
import Attachment from "../../../components/common/Attachment";
import { useTranslation } from "react-i18next";

const { v4: uuidv4 } = require("uuid");

const districtList = [
  { label: "Bagerhat", value: "Bagerhat" },
  { label: "Bandarban", value: "Bandarban" },
  { label: "Barguna", value: "Barguna" },
  { label: "Barishal", value: "Barishal" },
  { label: "Bhola", value: "Bhola" },
  { label: "Bogra", value: "Bogra" },
  { label: "Brahmanbaria", value: "Brahmanbaria" },
  { label: "Chandpur", value: "Chandpur" },
  { label: "Chapainawabganj", value: "Chapainawabganj" },
  { label: "Chattogram", value: "Chattogram" },
  { label: "Chuadanga", value: "Chuadanga" },
  { label: "Coxsbazar", value: "Coxsbazar" },
  { label: "Cumilla", value: "Cumilla" },
  { label: "Dhaka", value: "Dhaka" },
  { label: "Dinajpur", value: "Dinajpur" },
  { label: "Faridpur", value: "Faridpur" },
  { label: "Feni", value: "Feni" },
  { label: "Gaibandha", value: "Gaibandha" },
  { label: "Gazipur", value: "Gazipur" },
  { label: "Gopalganj", value: "Gopalganj" },
  { label: "Habiganj", value: "Habiganj" },
  { label: "Jamalpur", value: "Jamalpur" },
  { label: "Jashore", value: "Jashore" },
  { label: "Jhalokati", value: "Jhalokati" },
  { label: "Jhenaidah", value: "Jhenaidah" },
  { label: "Joypurhat", value: "Joypurhat" },
  { label: "Khagrachhari", value: "Khagrachhari" },
  { label: "Khulna", value: "Khulna" },
  { label: "Kishoreganj", value: "Kishoreganj" },
  { label: "Kurigram", value: "Kurigram" },
  { label: "Kushtia", value: "Kushtia" },
  { label: "Lakshmipur", value: "Lakshmipur" },
  { label: "Lalmonirhat", value: "Lalmonirhat" },
  { label: "Madaripur", value: "Madaripur" },
  { label: "Magura", value: "Magura" },
  { label: "Manikganj", value: "Manikganj" },
  { label: "Meherpur", value: "Meherpur" },
  { label: "Moulvibazar", value: "Moulvibazar" },
  { label: "Munshiganj", value: "Munshiganj" },
  { label: "Mymensingh", value: "Mymensingh" },
  { label: "Naogaon", value: "Naogaon" },
  { label: "Narail", value: "Narail" },
  { label: "Narayanganj", value: "Narayanganj" },
  { label: "Narsingdi", value: "Narsingdi" },
  { label: "Natore", value: "Natore" },
  { label: "Netrokona", value: "Netrokona" },
  { label: "Nilphamari", value: "Nilphamari" },
  { label: "Noakhali", value: "Noakhali" },
  { label: "Pabna", value: "Pabna" },
  { label: "Panchagarh", value: "Panchagarh" },
  { label: "Patuakhali", value: "Patuakhali" },
  { label: "Pirojpur", value: "Pirojpur" },
  { label: "Rajbari", value: "Rajbari" },
  { label: "Rajshahi", value: "Rajshahi" },
  { label: "Rangamati", value: "Rangamati" },
  { label: "Rangpur", value: "Rangpur" },
  { label: "Satkhira", value: "Satkhira" },
  { label: "Shariatpur", value: "Shariatpur" },
  { label: "Sherpur", value: "Sherpur" },
  { label: "Sirajganj", value: "Sirajganj" },
  { label: "Sunamganj", value: "Sunamganj" },
  { label: "Sylhet", value: "Sylhet" },
  { label: "Tangail", value: "Tangail" },
  { label: "Thakurgaon", value: "Thakurgaon" },
];

const defaultForm = {
  id: "",
  full_name: "",
  email: "",
  phone_number: "",
  address: "",
  district_id: "",

  created_by: "",
  created_date: "",
  updated_by: "",
  updated_date: "",
  Attachments: [],
};

const CreateCustomer = ({ isFromSell, setCustomer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);

  useEffect(() => {
    if (id === "new" || isFromSell) {
      setForm(defaultForm);
    } else {
      getCustomerData(id);
    }
  }, [id]);

  const getCustomerData = async (id) => {
    const response = await dispatch(getCustomer(id));
    const data = (await response) && response.payload && response.payload.data;
    setForm(data || null);
  };

  const canBeSubmit = () => {
    if (form) {
      const isValid = form.full_name !== ""; //&&
      // form.phone_number !== "" &&
      // form.address !== "";
      return isValid;
    }
    return false;
  };
  const handleRemoveFile = (id) => {
    let oldAttachments = JSON.parse(JSON.stringify(form.Attachments));
    let newAttachments = oldAttachments.filter((el) => el.id != id);
    setForm(_.set({ ...form }, `Attachments`, newAttachments));
  };

  const handleAddAttachment = (files) => {
    if (!files[0]) {
      return;
    }
    let tmpAtc = [...form.Attachments];
    var reader = new FileReader();
    function readFile(index) {
      if (index >= files.length) return;
      var file = files[index];
      reader.onload = function (e) {
        // get file content
        var bin = e.target.result;
        // do sth with bin

        const newAttachment = {
          id: uuidv4(),
          AttachedFile: `data:${files[0].type};base64,${btoa(bin)}`,
          Type: files[0].type,
          OriginalName: files[0].name,
          Size: files[0].size * 0.001,
          FilePath: "",
          is_thumb: true,
        };
        setForm(_.set({ ...form }, `Attachments`, [newAttachment]));
        readFile(index + 1);
      };
      reader.readAsBinaryString(file);
    }
    readFile(0);
  };
  const handleDescriptionChange = (id, event) => {
    event.preventDefault();
    let attachments = JSON.parse(JSON.stringify(form.Attachments));
    const updatedData = attachments.map((x) =>
      x.id === id ? { ...x, Description: event.target.value } : x
    );

    // let newAttachments = attachments.filter(el => el.id == id);
    // newAttachments.Description = event.target.value;
    setForm(_.set({ ...form }, `Attachments`, updatedData));
  };
  const onSubmit = () => {
    if (id === "new" || isFromSell) {
      const res = dispatch(saveCustomer(form))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
            if (isFromSell) {
              setCustomer(
                response && response.payload && response.payload.data
              );
            }
            resetForm();
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    } else {
      const res = dispatch(updateCustomer(form))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 200
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    }
  };

  const handleSelect = (e) => {
    const { name, value } = e.target;

    const updateForm = {
      ...form,
      [name]: value,
    };
    setForm({ ...updateForm });
  };
  return (
    <div className="p-4">
      <div className="w-full flex items-center justify-between">
        <div
          className="flex items-center gap-2 text-sm hover:!cursor-pointer text-slate-500"
          onClick={() => navigate("/customers", { replace: true })}
        >
          <ArrowBack sx={{ fontSize: 14 }} />
          {t("Back to list")}
        </div>
        <Button
          color="secondary"
          variant="contained"
          type="button"
          disabled={!canBeSubmit()}
          onClick={() => onSubmit(form)}
        >
          {id !== "new" ? t("UPDATE") : t("SAVE")}
        </Button>
      </div>
      {form && (
        <div className="my-6">
          <TextField
            label={t("Full Name")}
            name="full_name"
            value={form.full_name}
            onChange={handleChange}
            required
            error={form.full_name === ""}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t("Email")}
            type="email"
            name="email"
            value={form.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t("Phone")}
            name="phone_number"
            value={form.phone_number}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
            //error={form.phone_number === ""}
          />
          <TextField
            label={t("Address")}
            name="address"
            value={form.address}
            onChange={handleChange}
            required
            //error={form.address === ""}
            multiline
            rows={2}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("District")}</InputLabel>
            <Select
              name="district_id"
              label="District"
              value={form.district_id}
              onChange={handleSelect}
            >
              {districtList &&
                districtList.length > 0 &&
                districtList.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
};

export default CreateCustomer;
