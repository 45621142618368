import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";

import { ArrowBack } from "@mui/icons-material";
import _ from "../../../util/lodash";
import toast from "react-hot-toast";
import useForm from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getProduct,
  saveProduct,
  updateProduct,
} from "../../../redux/product/productSlice";
import DropzoneFileUploader from "../../../components/common/DropzoneFileUploader";
import Attachment from "../../../components/common/Attachment";
import { getProductCategorys } from "../../../redux/product-category/productCategorySlice";
import { getProductSubCategorys } from "../../../redux/product-subcategory/productSubCategorySlice";
import { getProductPriceLevels } from "../../../redux/product-price-level/productPriceLevelSlice";
import { getProductBrands } from "../../../redux/product-brand/productBrandSlice";
import { getProductSellingMethods } from "../../../redux/product-selling-method/productSellingMethodSlice";
import { getProductUnits } from "../../../redux/product-unit/productUnitSlice";
import { getBarcodeSymbologys } from "../../../redux/barcode-symbology/barcodeSymbologySlice";
import { CaretList, ProductUnitEnum } from "../../../actionTypes";

import { Util } from "../../../util/Util";
import { useTranslation } from "react-i18next";
const util = new Util();

const { v4: uuidv4 } = require("uuid");

const defaultForm = {
  id: "",
  product_name: "",
  product_code: "",
  product_category_id: "",
  sub_category_id: "",
  product_price_level_id: "",
  barcode_symbology: "65a27171-2a9a-4221-bf9e-d0043ddc5469",
  brand_id: "",
  unit_id: "",
  purchase_unit_id: "",
  sale_unit_id: "",
  product_purchase_price: 0,
  product_sell_price: 0,
  product_description: "",
  product_thumbnail_image_id: "",
  product_selling_method_id: "58a1c975-eec0-432c-9a02-b31e074aa5c6",
  measurement_size: 0,

  weight_in_vori: 0,
  weight_in_ana: 0,
  weight_in_roti: 0,
  weight_in_point: 0,

  caret_id: "",

  created_by: "",
  created_date: "",
  updated_by: "",
  updated_date: "",
  Attachments: [],
};

const CreateProduct = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);
  const categoryList = useSelector(
    (state) => state.productCategory.productCategorys
  );
  const subCategoryList = useSelector(
    (state) => state.productSubCategory.productSubCategorys
  );
  const priceLevelList = useSelector(
    (state) => state.productPriceLevel.productPriceLevels
  );
  const brandList = useSelector((state) => state.productBrand.productBrands);
  const unitList = useSelector((state) => state.productUnit.productUnits);
  const sellingMethodList = useSelector(
    (state) => state.productSellingMethod.productSellingMethods
  );
  const barcodeList = useSelector(
    (state) => state.barcodeSymbology.barcodeSymbologys
  );

  useEffect(() => {
    dispatch(getProductCategorys());
    dispatch(getProductSubCategorys());
    dispatch(getProductPriceLevels());
    dispatch(getProductBrands());
    dispatch(getProductUnits());
    dispatch(getProductSellingMethods());
    dispatch(getBarcodeSymbologys());
    if (id === "new") {
      setForm(defaultForm);
    } else {
      getSlider(id);
    }
  }, [id]);

  const getSlider = async (id) => {
    const response = await dispatch(getProduct(id));
    const data = (await response) && response.payload && response.payload.data;
    setForm(data || null);
  };

  const canBeSubmit = () => {
    if (form) {
      let isValidUnit = true;
      if (form.unit_id === ProductUnitEnum.Vori) {
        isValidUnit =
          form.weight_in_roti >= 0 &&
          form.weight_in_ana >= 0 &&
          form.weight_in_roti >= 0 &&
          form.weight_in_point >= 0;
      }
      if (form.unit_id === ProductUnitEnum.Gram) {
        isValidUnit = form.measurement_size > 0;
      }

      const isValid =
        form.product_name !== "" &&
        form.product_code !== "" &&
        form.product_category_id !== "" &&
        // form.sub_category_id !== "" &&
        // form.product_price_level_id !== "" &&
        isValidUnit &&
        form.barcode_symbology !== "" &&
        //form.brand_id !== "" &&
        form.purchase_unit_id !== "" &&
        form.sale_unit_id !== "" &&
        form.product_selling_method_id !== "";
      return isValid;
    }
    return false;
  };

  const handleRemoveFile = (id) => {
    let oldAttachments = JSON.parse(JSON.stringify(form.Attachments));
    let newAttachments = oldAttachments.filter((el) => el.id != id);
    setForm(_.set({ ...form }, `Attachments`, newAttachments));
  };

  const handleAddAttachment = (files) => {
    if (!files[0]) {
      return;
    }
    let tmpAtc = [...form.Attachments];
    var reader = new FileReader();
    function readFile(index) {
      if (index >= files.length) return;
      var file = files[index];
      reader.onload = function (e) {
        // get file content
        var bin = e.target.result;
        // do sth with bin

        const newAttachment = {
          id: uuidv4(),
          AttachedFile: `data:${files[0].type};base64,${btoa(bin)}`,
          Type: files[0].type,
          OriginalName: files[0].name,
          Size: files[0].size * 0.001,
          FilePath: "",
          is_thumb: true,
        };
        setForm(_.set({ ...form }, `Attachments`, [newAttachment]));
        readFile(index + 1);
      };
      reader.readAsBinaryString(file);
    }
    readFile(0);
  };
  const handleDescriptionChange = (id, event) => {
    event.preventDefault();
    let attachments = JSON.parse(JSON.stringify(form.Attachments));
    const updatedData = attachments.map((x) =>
      x.id === id ? { ...x, Description: event.target.value } : x
    );

    // let newAttachments = attachments.filter(el => el.id == id);
    // newAttachments.Description = event.target.value;
    setForm(_.set({ ...form }, `Attachments`, updatedData));
  };
  const onSubmit = () => {
    if (id === "new") {
      const res = dispatch(saveProduct(form))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
            resetForm();
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    } else {
      const res = dispatch(updateProduct(form))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 200
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    }
  };

  const handleSelectUnit = (e) => {
    const { name, value } = e.target;
    if (name === "unit_id") {
      const updateForm = {
        ...form,
        [name]: value,
        measurement_size: 0,
        weight_in_vori: 0,
        weight_in_ana: 0,
        weight_in_roti: 0,
        weight_in_point: 0,
        purchase_unit_id: value,
        sale_unit_id: value,
      };
      setForm({ ...updateForm });
    }
  };
  const handleSelect = (e) => {
    const { name, value } = e.target;
    if (name === "product_selling_method_id") {
      const updateForm = {
        ...form,
        [name]: value,
        product_price_level_id: "",
      };
      setForm({ ...updateForm });
    } else if (name === "unit_id") {
      const updateForm = {
        ...form,
        [name]: value,
        measurement_size: 0,
        weight_in_vori: 0,
        weight_in_ana: 0,
        weight_in_roti: 0,
        weight_in_point: 0,
      };
      setForm({ ...updateForm });
    } else {
      const updateForm = {
        ...form,
        [name]: value,
      };
      setForm({ ...updateForm });
    }
  };

  const makeGramToVori = (gram) => {
    const { vori, ana, roti, point } = util.convertToVori(gram);
    return (
      vori +
      t("vori") +
      ", " +
      ana +
      t("ana") +
      ", " +
      roti +
      t("roti") +
      ", " +
      point +
      t("point")
    );
  };

  return (
    <div className="p-4">
      <div className="w-full flex items-center justify-between">
        <div
          className="flex items-center gap-2 text-sm hover:!cursor-pointer text-slate-500"
          onClick={() => navigate("/products", { replace: true })}
        >
          <ArrowBack sx={{ fontSize: 14 }} />
          {t("Back to list")}
        </div>
        <Button
          color="secondary"
          variant="contained"
          type="button"
          disabled={!canBeSubmit()}
          onClick={() => onSubmit(form)}
        >
          {id !== "new" ? t("Update") : t("Save")}
        </Button>
      </div>
      <div className="my-6 w-full flex flex-col gap-2">
        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <TextField
            label={t("Product Name")}
            value={form.product_name}
            error={form.product_name === ""}
            fullWidth
            margin="normal"
            variant="outlined"
            required
            name="product_name"
            onChange={handleChange}
          />

          <TextField
            label={t("Product Code")}
            value={form.product_code}
            error={form.product_code === ""}
            fullWidth
            margin="normal"
            variant="outlined"
            name="product_code"
            onChange={handleChange}
          />
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>{t("Product Category")}</InputLabel>
            <Select
              value={form.product_category_id}
              onChange={handleSelect}
              label={t("Product Category")}
              name="product_category_id"
              error={form.product_category_id === ""}
            >
              {categoryList &&
                categoryList.length > 0 &&
                categoryList.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>{t("Sub Category")}</InputLabel>
            <Select
              value={form.sub_category_id}
              onChange={handleSelect}
              label={t("Sub Category")}
              name="sub_category_id"
            >
              {subCategoryList &&
                subCategoryList.length > 0 &&
                subCategoryList.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>{t("Barcode Symbology")}</InputLabel>
            <Select
              value={form.barcode_symbology}
              onChange={handleSelect}
              label={t("Barcode Symbology")}
              name="barcode_symbology"
              error={form.barcode_symbology === ""}
            >
              {barcodeList &&
                barcodeList.length > 0 &&
                barcodeList.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>{t("Brand")}</InputLabel>
            <Select
              value={form.brand_id}
              onChange={handleSelect}
              label={t("Brand")}
              name="brand_id"
              // error={form.brand_id === ""}
            >
              {brandList &&
                brandList.length > 0 &&
                brandList.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>{t("Unit")}</InputLabel>
            <Select
              value={form.unit_id}
              onChange={handleSelectUnit}
              label={t("Unit")}
              name="unit_id"
              error={form.unit_id === ""}
            >
              {unitList &&
                unitList.length > 0 &&
                unitList.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
          {form && form.unit_id === ProductUnitEnum.Vori ? (
            <div className="w-full flex flex-col md:flex-row gap-4">
              <TextField
                label={t("Vori")}
                value={form.weight_in_vori}
                fullWidth
                type="number"
                margin="normal"
                variant="outlined"
                name="weight_in_vori"
                onChange={handleChange}
              />
              <TextField
                label={t("Ana")}
                value={form.weight_in_ana}
                fullWidth
                type="number"
                margin="normal"
                variant="outlined"
                name="weight_in_ana"
                onChange={handleChange}
              />
              <TextField
                label={t("Roti")}
                value={form.weight_in_roti}
                fullWidth
                type="number"
                margin="normal"
                variant="outlined"
                name="weight_in_roti"
                onChange={handleChange}
              />
              <TextField
                label={t("Point")}
                value={form.weight_in_point}
                fullWidth
                type="number"
                margin="normal"
                variant="outlined"
                name="weight_in_point"
                onChange={handleChange}
              />
            </div>
          ) : (
            <TextField
              label={t("Weight")}
              value={form.measurement_size}
              fullWidth
              type="number"
              margin="normal"
              variant="outlined"
              name="measurement_size"
              error={form.measurement_size === 0}
              onChange={handleChange}
            />
          )}
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          {form && form.unit_id === ProductUnitEnum.Vori && (
            <label className="w-full font-bold text-sm">
              {util.convertToGram(
                form.weight_in_vori,
                form.weight_in_ana,
                form.weight_in_roti,
                form.weight_in_point
              )}
              &nbsp; {t("grams")}
            </label>
          )}
          {form && form.unit_id === ProductUnitEnum.Gram && (
            <label className="w-full font-bold text-sm">
              {makeGramToVori(form.measurement_size)}
            </label>
          )}
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>{t("Purchase Unit")}</InputLabel>
            <Select
              value={form.purchase_unit_id}
              onChange={handleSelect}
              label={t("Purchase Unit")}
              name="purchase_unit_id"
              error={form.purchase_unit_id === ""}
            >
              {unitList &&
                unitList.length > 0 &&
                unitList.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>{t("Sale Unit")}</InputLabel>
            <Select
              value={form.sale_unit_id}
              onChange={handleSelect}
              label={t("Sale Unit")}
              name="sale_unit_id"
              error={form.sale_unit_id === ""}
            >
              {unitList &&
                unitList.length > 0 &&
                unitList.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>{t("Caret")}</InputLabel>
            <Select
              value={form.caret_id}
              onChange={handleSelect}
              label={t("Caret")}
              name="caret_id"
              error={form.caret_id === ""}
            >
              {CaretList &&
                CaretList.length > 0 &&
                CaretList.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>{t("Product Selling Method")}</InputLabel>
            <Select
              value={form.product_selling_method_id}
              onChange={handleSelect}
              label={t("Product Selling Method")}
              name="product_selling_method_id"
              error={form.product_selling_method_id === ""}
            >
              {sellingMethodList &&
                sellingMethodList.length > 0 &&
                sellingMethodList.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
          {form.product_selling_method_id ===
            "4251923d-6b2f-4eff-9b10-0f233d47a829" && (
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>{t("Product Price Level")}</InputLabel>
              <Select
                value={form.product_price_level_id}
                onChange={handleSelect}
                label={t("Product Price Level")}
                name="product_price_level_id"
              >
                {priceLevelList &&
                  priceLevelList.length > 0 &&
                  priceLevelList.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <TextField
            label={t("Product Purchase Price")}
            value={form.product_purchase_price}
            fullWidth
            type="number"
            margin="normal"
            variant="outlined"
            name="product_purchase_price"
            onChange={handleChange}
          />

          <TextField
            label={t("Product Sell Price")}
            value={form.product_sell_price}
            fullWidth
            type="number"
            margin="normal"
            variant="outlined"
            name="product_sell_price"
            onChange={handleChange}
          />
        </div>

        <TextField
          label={t("Product Description")}
          value={form.product_description}
          fullWidth
          margin="normal"
          variant="outlined"
          multiline
          rows={4}
          name="product_description"
          onChange={handleChange}
        />
        <div>
          <label className="text-sm font-semibold">
            {t("Upload Product Thumbnail")}
          </label>
          <div className="mb-6 w-full">
            <DropzoneFileUploader handleAddAttachment={handleAddAttachment} />
          </div>
          <div className="pt-8">
            {form.Attachments &&
              form.Attachments.length > 0 &&
              form.Attachments.map((file) => (
                <div className="flex flex-1 items-center" key={Math.random()}>
                  <div className="w-full flex items-start gap-4 flex-col md:flex-row">
                    <div className="attachment flex-1 break-all">
                      <Attachment
                        fileName={file.OriginalName}
                        filePath={file.FilePath}
                        size={`${file.Size.toFixed(2)} KB`}
                        id={file.id}
                        module="security"
                        handleRemoveFile={handleRemoveFile}
                        canRemove={true}
                        className
                      />
                    </div>
                    {/* <div className="flex-1">
                      <TextField
                        className="mx-12"
                        id="Description"
                        name="Description"
                        onChange={(event) =>
                          handleDescriptionChange(file.id, event)
                        }
                        label="Description"
                        type="text"
                        value={file.Description}
                        variant="standard"
                        fullWidth
                      />
                    </div> */}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProduct;
